import './App.css';

function wip() {
    return (
            <div className="Wip">
                <h1>Work in progress</h1>
                    <h2>Contact tim@vdperk.com for questions regarding missing content</h2>
            </div>
    );
}
export default wip;
