
import './App.css';
import port1 from "./Screenshot_1.png";
import port2 from "./Screenshot_2.png";
import port3 from "./Screenshot_3.png";
import cv from "./CV2.docx";
import phish from "./phishcamp.docx";
import plogo from "./phishlogo.png";

function Home() {
  return (
<html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="Tim van der Perk" content="" />
        <title>Tims Portfolio</title>
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />

        <script src="https://use.fontawesome.com/releases/v5.15.3/js/all.js" crossorigin="anonymous"></script>

        <link href="https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic" rel="stylesheet" type="text/css" />

        <link href="css/styles.css" rel="stylesheet" />
    </head>
    <body id="page-top">
        <nav id="sidebar-wrapper">
            <ul class="sidebar-nav">
                <li class="sidebar-brand"><a href="#page-top">Start Bootstrap</a></li>
                <li class="sidebar-nav-item"><a href="#page-top">Home</a></li>
                <li class="sidebar-nav-item"><a href="#about">About</a></li>
                <li class="sidebar-nav-item"><a href="#services">Services</a></li>
                <li class="sidebar-nav-item"><a href="#portfolio">Portfolio</a></li>
                <li class="sidebar-nav-item"><a href="#contact">Contact</a></li>
            </ul>
        </nav>
        <header class="masthead d-flex align-items-center">
            <div class="container px-4 px-lg-5 text-center">
                <h1 class="mb-1" id="bigheader">Tim van der Perk</h1>
                <h3 class="mb-5"><em>Student Computer Science in Rotterdam</em></h3>
                <a class="btn btn-primary btn-xl" href="#about">About Me</a>
            </div>
        </header>
        <section class="content-section bg-light" id="about">
            <div class="container px-4 px-lg-5 text-center">
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-10">
                        <h2>Want to know more about me? See my resume!</h2>
                        <p class="lead mb-5">
                            For my socials and projects; keep scrolling!
                        </p>
                              <a class="btn btn-dark btn-xl" href={cv} download> See Resume</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section bg-primary text-white text-center" id="services">
            <div class="container px-4 px-lg-5">
                <div class="content-section-heading">
                    <h3 class="text-secondary mb-0">Socials</h3>
                    <h2 class="mb-5">Find Me Here</h2>
                </div>
                <div class="row gx-4 gx-lg-5">
                          <div class="col-lg-3 col-md-6 mb-5 mb-lg-0" ><a id="links" href="https://www.linkedin.com/in/tim-van-der-perk-a92ab81a3/">
                              <span class="service-icon rounded-circle mx-auto mb-3"><i class="icon-social-linkedin"></i></span>
                              <h4><strong>LinkedIn</strong></h4></a>
                        <p class="text-faded mb-0">Let's make a connection!</p>
                    </div>
                          <div class="col-lg-3 col-md-6 mb-5 mb-lg-0"><a id="links" href="https://github.com/Timvdperk">
                              <span class="service-icon rounded-circle mx-auto mb-3"><i class="icon-social-github"></i></span>
                        <h4><strong>GitHub</strong></h4></a>
                        <p class="text-faded mb-0">Scroll through some code!</p>
                    </div>
                          <div class="col-lg-3 col-md-6 mb-5 mb-md-0"><a id="links" href="https://www.instagram.com/timvdperk">
                            <span class="service-icon rounded-circle mx-auto mb-3"><i class="icon-social-instagram"></i></span>
                            <h4><strong>Instagram</strong></h4></a>
                        <p class="text-faded mb-0">
                            See my face!
                        </p>
                    </div>
                          <div class="col-lg-3 col-md-6"><a id="links" href="mailto:timvdperk@gmail.com">
                              <span class="service-icon rounded-circle mx-auto mb-3"><i class="icon-envelope" ></i></span>
                              <h4><strong>Email</strong></h4></a>
                        <p class="text-faded mb-0">Send me some questions!</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section" id="portfolio">
            <div class="container px-4 px-lg-5">
                <div class="content-section-heading text-center">
                    <h3 class="text-secondary mb-0">Portfolio</h3>
                    <h2 class="mb-5">Recent Projects</h2>
                </div>
                <div class="row gx-0">
                    <div class="col-lg-6">
                        <a class="portfolio-item" href="https://www.thefriendshow.nl">
                            <div class="caption">
                                <div class="caption-content">
                                    <div class="h2">The Friendshow</div>
                                    <p class="mb-0">A website made by me for a Podcast I host with friends.</p>
                                </div>
                            </div>
                                  <img class="img-fluid" src={port1} alt="noalt" />
                        </a>
                          </div>
                    <div class="col-lg-6">
                              <a class="portfolio-item" href={phish} download>
                            <div class="caption">
                                <div class="caption-content">
                                    <div class="h2">Phishing campaign</div>
                                    <p class="mb-0"> A phishing campaign targetting HR students(Click for report download)</p>
                                </div>
                            </div>
                            <img class="img-fluid" src={plogo} alt="..." />
                        </a>
                    </div>
                    <div class="col-lg-6">
                        <a class="portfolio-item" href="./workinprogress">
                            <div class="caption">
                                <div class="caption-content">
                                    <div class="h2">Timeplanner</div>
                                    <p class="mb-0"> A multifunctional employee system</p>
                                </div>
                            </div>
                                  <img class="img-fluid" src={port2} alt="..." />
                        </a>
                    </div>
                    <div class="col-lg-6">
                        <a class="portfolio-item" href="./workinprogress">
                            <div class="caption">
                                <div class="caption-content">
                                    <div class="h2">Opendag App</div>
                                    <p class="mb-0"> An application made for the open day of HR</p>
                                </div>
                            </div>
                            <img class="img-fluid" src={port3} alt="..." />
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <footer class="footer text-center">
            <div class="container px-4 px-lg-5">
                <p class="text-muted small mb-0">Copyright &copy; vdperk.com - 2021</p>
            </div>
        </footer>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/js/bootstrap.bundle.min.js"></script>
        <script src="./scripts.js"></script>
    </body>
</html>

  );
}

export default Home;
