
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home.js';
import wip from './wip.js';

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/workinprogress" component={wip} />
                    <Redirect to="/404"/>
                </Switch>
            </div>
        </BrowserRouter>
    );
}
export default App;
